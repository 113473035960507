.selectFile--container{
    width: 100%;
    margin: 0.5rem 0;
    select{
        border: none;
        width: 100%;
        height: 32px;
        transition: all 0.3s;
        font-size: var(--font-size-tertiary);
        color: var(--color-neutral-dark-II);
        border: 1px solid var(--color-neutral-light-I);
        &:hover{
            transition: all 0.3s;
            border: 2px solid var(--color-neutral-base);
        }
        &:focus{
            outline: none;
            border: none;
            box-shadow: 0px 0px 6px 2px var(--color-neutral-light-I);
        }
        .active{
            background: var(--color-neutral-light-II);
        }
        option:hover{
            background:red;
        }
        
    }
}

.ms-Dropdown:focus::after{
    // opacity: 0;
    outline: none;
    border: none;
    transition: all 0.3s;
    box-shadow: 0px 0px 10px 3px var(--color-neutral-light-I);
    
}

.ms-Dropdown{
    &:active{
        border: 1px solid var(--color-neutral-dark-II) 0.5;
    }
    
}

.ms-Dropdown-title{
    // opacity: 1;
        color: var(--color-neutral-base);
        
    border-color: var(--color-neutral-light-I);
    &:hover{
        opacity: 0.4;
        border-color: var(--color-neutral-base);
    }
    &:focus{
        .ms-Dropdown-titleIsPlaceHolder{
            color:var(--color-neutral-base);
        }
        
    }
}


