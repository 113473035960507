.container--imgquat{
    width: 100%;
    margin: 20px 0;
    display: flex;
    justify-content: center;
    img{
        width: 140px;
        height: 140px;
        background: var(--color-neutral-base);
    }
}