.rat{
    width: 100vw;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    background: #ffffff;
    animation-name: fondo;
    animation-duration: 3s;
    display: flex;
}

.padd{
    //margin-top: 12%;
    //padding: 3rem;
    //background-color: aqua;
    width: 50%;
}

.padd2{
    //margin-top: 12%;
    padding: 3rem;
    //background-color: orange;
    width: 45%;
}

.tar{
    position: absolute;
    padding: 3rem;
}


    i.email{
        font-size: 30px;
        color: #0C3273;
        animation-name: animate;
        animation-duration: 3s;
    }


.alert{
    margin-top: 19%;
    animation-name: animate;
    animation-duration: 3s;
}
.paragraf{
    color: #0C3273; 
    padding:0.6em 0 4em 0;
    line-height: 1.5;
    animation-name: animate;
    animation-duration: 3s;
}

#linetext{
    float: left;
}

.linetext{
    color: #0C3273; 
    padding:0.6em 0 4em 0;
    line-height: 1.5;
    animation-name: animate;
    animation-duration: 3s;
    margin-top: -2px;
}
.dark{
    margin-top: 12%;
    margin-left: 9%;
    position: absolute;
    opacity: 1;
    z-index: 3;
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    animation-duration: 3s;
    animation-name: animate;
}
.light{
    margin-top: 12%;
    margin-left: 9%;
    position: absolute;
    opacity: 1;
    z-index: 3;
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    animation-name: animate;
    animation-duration: 3s;

}
.dark:hover{
    opacity: 0;
}

@keyframes fondo{
    from {background-color: #08204A;}
      to {background-color: #ffffff;}
}

@keyframes animate{
    from {opacity: 0;}
      to {opacity: 1;}
}

@media only screen and (max-width: 400px) {
    .rat{
        display: inline;
    }
    .padd2 {
        padding: 10%;
        width: 80%;
        text-align: center;
        //background-color: aqua;
    }
    #linetext{
        float: none;
    }
    /* .padd{
        background-color: orange;
    } */
    .padd{
        opacity: 0;
    }
}