.container--login{
    top: 0;
    left: 0;
    height: 100%;
  background-position: center;
  background-size: cover;
  min-height: 100vh; 
  background-repeat: no-repeat;
    // background: url('../../../assets/img/background.webp');
    width: 62%;
    display: grid;
}

.container--login.degradado::after{
    content:"";
    position: absolute;
    top: 0;
    left: 0;
    width: 99vw;
    height: 100%;
    // background: linear-gradient(to bottom right, gray, white, gray, white, gray, white, gray, white, gray);
    opacity:0.8;
    
}

.main__container{
    width: 100%;
    display: flex;
    position: fixed;
}
    
.main__container-img{
    width: 100%;
    height: 99.5%;
}

.container--curve{
    top: 0;
    position: fixed;
    left: 0;
    width: 100vw;
    height: 100%;
    background-position: center center;
    background: url('../../../assets/img/curve.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    display: flex;
    align-items: center;

}

.login--fond-degrade{
    width: 38%;
    height: revert;
    display: flex;
    // padding: 1% 3%;
    justify-content: flex-end;
    align-items: center;
    //background: var(--color-neutral-light-III);
    background-attachment: inherit;
    background-repeat: no-repeat;
    background-size: cover;
    .login--fond-base{
        width: 32%;
        // max-width: 350px;
        min-width: 300px;
        height: 100%;
        min-height: 300px;
        z-index: 10;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: center;
        align-content: space-between;
        background-color: var(--color-neutral-light-III);
        position: fixed;
        overflow-y: scroll;
        padding: 10%;
        @media only screen and (max-width: 820px) {
            width: 85%;
        }
        @media only screen and (min-width: 820px) {
            padding: 3%;
        }
        @media only screen and (max-height: 700px) {
            top: -6%;
        }
        .login--container-header{
            width: 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
        }
        .login--container-main{
            width: 100%;
            margin: 20px 0;
            display: flex;
            height: auto;
            min-height: 370px;
            flex-wrap: wrap;
            flex-direction: column;
            // justify-content: center;
            align-items: center;
            .login--pregraft-default{
                color: var(--color-neutral-dark-II);
                font-size: var(--font-size-secondary);
            }
            .container--choicegroup{
                width: 100%;
                .ms-ChoiceField-wrapper{
                    margin: 10px 0;
                }
                span{
                    font-size: var(--font-size-secondary);
                }
            }
            .ms-Checkbox{
                margin: 10px 0 20px 0;
                span{
                    font-size: var(--font-size-tertiary);
                }
                .ms-Checkbox-checkbox{
                    // background: var(--color-neutral-light-III);
                    // border-color: var(--color-primary);
                    &:hover{
                        border-color: var(--color-primary);
                    }
                    
                }
                .checkbox-165{
                    background: var(--color-primary);
                    border-color: var(--color-primary);
                    &:hover{
                        background: var(--color-light);
                        border-color: var(--color-light);
                    }
                }
            }
            .login-user{
                height: 40vh;
                display: flex;
                flex-flow: column;
    
                width: 100%;
                margin: 12% 0 10px 0;
                font-weight: 500;
                letter-spacing: -1px;
                color: var(--color-neutral-dark-II);
                font-size: var(--font-size-primary);
            }
            .hyperText-TyC{
                color: var(--color-primary);
                cursor: pointer;
                text-decoration-line: none;
            }
            .ms-TextField{
                margin: 4px 0 10px 0;
                width: 100%;
                .fieldGroup-90{
                    border: none;
                    box-shadow: 0px 0px 10px 2px var(--color-neutral-light-I);
                }
                label{
                    margin: 10px 0 5px 0;
                    font-weight: 400;
                    font-size: var(--font-size-secondary);
                }
            }
            >img{
                height: 70px;
                width: auto;
                display: flex;
                margin-left: auto;
                margin-right: auto;
            }
            >p{
                font-size: var(--font-size-secondary);
                color: var(--color-neutral-dark-III);
                text-align: left;
            }
            >label{
                width: 100%;
                margin: 2px 0 10px 0;
                font-weight: 500;
                letter-spacing: -1px;
                color: var(--color-neutral-dark-II);
                font-size: var(--font-size-primary);
            }
            .root-41{
                width: 95%;
                label{
                    font-weight: 400;
                    font-size: var(--font-size-secondary);
                }
            }
            .root-57{
                width: 100%;
                input{
                    height: 32px;
                }
            } 
            .recaptcha--container{
                width: 100%;
                height: 75px;
                margin: 20px 0;
                background: var(--color-neutral-base);
            }  
        }
        .login--container-footer{
            width: 100%;
        }
    }
}

.logo-client{
    height: 70px;
    width: auto;
    display: flex;
    margin-left: auto;
    margin-right: auto;
}

@media only screen and (max-width: 820px) and (min-width: 770px) {
    .estile-computer{
       // background: red;
        display: none;
    }
    .login--fond-degrade{
        background: white;
        justify-content: center;
        width: 100%;
        .login--fond-base{
            width: 90%;
            // max-width: 450px;
            min-width: 300px;
            height: 100%;
            min-height: 300px;
            z-index: 10;
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            justify-content: center;
            align-content: space-between;
            background-color: var(--color-neutral-light-III);

        }
        .login--container-header{
            width: 95%;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            // position: absolute;
        }
        .login--container-main{
            width: 100%;
            margin: 28vh 0;
            display: flex;
            height: auto;
            min-height: 480px;
            flex-wrap: wrap;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        #abajo{
            // position:absolute; /*El div será ubicado con relación a la pantalla*/
            left:0px; /*A la derecha deje un espacio de 0px*/
            right:0px; /*A la izquierda deje un espacio de 0px*/
            bottom:0px; /*Abajo deje un espacio de 0px*/
            height:50px; /*alto del div*/
        }
    }
    .main__container{
        width: 100%;
        display: flex;
        position: inherit;
    }
        
}

@media only screen and (max-width: 769px) {
    .estile-computer{
       // background: red;
        display: none;
    }
    .login--fond-degrade{
        width: 100%;
        background: white;
        justify-content: center;
    }
    .main__container{
        width: 100%;
        display: flex;
        position: inherit;
    }
    
}

@media only screen and (min-width: 1680px) {
    .logo-client{
        height: 140px;
        width: auto;
    }
}

.camp-radio{
    justify-content: space-around;
    display: flex;
    align-items: center;
    .radius-opt{
        input[type="radio"]{
            margin-top: 0px;
        }
        display: flex;
        align-items: center;
        accent-color: var(--color-primary);
        
    }

}

.mrg-button{
    margin-top: 20px;
}

.politics{
    font-size: var(--font-size-pentanary);
    color: var(--color-primary);
    font-weight: 600;
}
// .checkbox-177{
//     border: 1px solid var(--color-primary);
//     background: var(--color-primary);
    
    
// }

.mg-tp15{
    width: 100%;
    margin: 2px 0 10px 0;
    font-weight: 500;
    letter-spacing: -1px;
    color: var(--color-neutral-dark-II);
    font-size: var(--font-size-primary);
}
.button-tyc{
    padding: 0 0 10px 0;
}
.c--fit-cont{width:fit-content;}

#abajo{
    // position: fixed;

bottom: 0;
}

.mensaje {
    position: sticky;
    top: -100px;
    width: 80%;
    height: 30px;
    background: #F5D4D4;
    border: 1px solid #F5D4D4;
    border-radius: 5px;
    padding: 0px 10px;
    animation: slide-in 0.5s forwards;
    color:#CC0000;
    display: flex;
    align-items: center;
    margin-top: 3px;
  }
  .mensaje:before {
    content: '';
    position: absolute;
    top: -20px;
    left: 18%;
    transform: translateX(-50%);
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent #F5D4D4 transparent;
  }
  
  @keyframes slide-in {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }

  .df{
    >h3{
        width: 100%;
        margin: 2px 0 10px 0;
        font-weight: 500;
        // letter-spacing: -1px;
        color: var(--color-neutral-dark-II);
        font-size: 24px;
    }
  }

  .txt-c{text-align: center;}

  .grecaptcha-badge{
    z-index: 150;
    top: 80%;
  }