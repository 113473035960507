.container--general{
    // width: 150%;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-around;
    background-color: #ffffff;
    .container-center{
        // margin-top: 2%;
        background-color: #ffffff;
        width: 45%;
        height: auto;
        box-shadow: rgba(0, 0, 0, 0.2) 0px 60px 40px -7px;
        //box-shadow: rgba(0, 0, 0, 0.45) 10px 25px 20px -20px;
      
       i.icon-warn{
           font-size: 32px;
        //    padding: 0 10px 0 15%;
           color: var(--color-neutral-dark-II);
       }
       .image-top{
           margin: 0 0 20px -12px;
           width: 104%;           
       } 
       .color-text{
        color: var(--color-neutral-dark-II);
         font-size: 21px; 
         margin: 0;
         text-align: center;
    }
       
       .info{
        display: flex;

       }
    }
    footer{
        width: 100%;
        position: absolute;
        bottom: 0%;
        
       }
}


.cont-down{
    // margin-top: 25px;
    background-color: #ffffff;
    width: auto;
    height: auto;
    .warn-message{
        padding: 1% 10%;
        color: var(--color-neutral-dark-II);
    }
    .txt-b{
        font-weight: bold;
    }
    .txto{
        text-align: left;
        margin: 0px 1px 0px 35px;
        color: var(--color-neutral-dark-II);
    }
    .izqu{
        float: left;
    }
    .div-button{clear: both;}
    .padd-button{
        margin:20px 15px; 
        min-width: 170px;
        min-height: 32px;
        border-radius: 4px;
    }
    .padd-button:hover{
        opacity: 0.9;
    }
    .pstyle{
        width: auto;
        margin: 8px 0;
        text-align: center;
    }
}

.admin--container-footer{
    width: 100%;
    height: 60px;
    display: flex;
    color: var(--color-neutral-dark-II);
    justify-content: center;
    align-items: center;
    font-size: 20px;
    background-color: var(--color-neutral-light-II);
    
    h6{
        font-weight: 400;
        margin-right: 15px;
    }
}
@media only screen and (max-width: 400px) {
    
    .container--general{
        width: 100%;
         height: 779px;  
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-around;
        background-color: #ffffff;
        .container-center{
            // margin-top: 40px;
            background-color: #ffffff;
            background-size: cover;
            width: 90%;
            height: 60%;
            box-shadow: rgba(0, 0, 0, 0.2) 0px 60px 40px -7px;
            //box-shadow: rgba(0, 0, 0, 0.45) 10px 25px 20px -20px;
           
           i.icon-warn{
               font-size: 32px;
               padding: 0 10px 0 15%;
               color: var(--color-neutral-dark-II);
               padding: 0 0 0 22px;
           }
           .image-top{
               margin: 0 0 20px -7px;
               width: 104%;
           }
           .color-text{
            color: var(--color-neutral-dark-II); 
            font-size: 21px; 
            margin: 0;
            text-align: center;
            }

           .info{
            display: flex;
            flex-direction: column;
            align-items: center;
           }
           
        }
        footer{
            width: 100%;
            position: absolute;
            bottom: -52%;
            
           }
    }
    .cont-down{
        .pstyle{
            width: 100%;
            text-align: center;
        }
        .padd-button{
            margin:15px 15px; 
            min-width: 170px;
            min-height: 32px;
            border-radius: 4px;
        }
        .txto{
            // text-align: left;
            align-items: center;
            color: var(--color-neutral-dark-II);
        }
    }
  }
  @media only screen and (max-width: 700px) {
    
    .container--general{
        width: 100%;
        //  height: 779px;  
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-around;
        .container-center{
            // margin-top: 40px;
            background-color: #ffffff;
            background-size: cover;
            width: 90%;
            height: 60%;
            box-shadow: rgba(0, 0, 0, 0.2) 0px 60px 40px -7px;
            //box-shadow: rgba(0, 0, 0, 0.45) 10px 25px 20px -20px;
           
           i.icon-warn{
               font-size: 32px;
               padding: 0 10px 0 15%;
               color: var(--color-neutral-dark-II);
           }
           .image-top{
               margin: 0 0 20px -7px;
               width: 104%;
           }
           .color-text{color: var(--color-neutral-dark-II); font-size: 21px; margin: 0;}
        }
        footer{
            width: 100%;
            position: fixed;
            // top:100vh;
            bottom:0;
           }
    }
    .cont-down{
        .pstyle{
            width: 100%;
        }
        .padd-button{
            margin:15px 15px; 
            min-width: 170px;
            min-height: 32px;
            border-radius: 4px;
        }
    }
  }

  @media only screen and (max-width: 900px) {
    .cont-down{
        .padd-button{
            margin:15px 15px; 
            min-width: 40%;
            min-height: 32px;
            border-radius: 4px;
        }
    }
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    border: 1px solid #ddd;
    border-color:transparent;
  }
  
  th, td {
    text-align: left;
    padding: 4px;
    p{
        margin: 0;
    }
  }


  .pdg-lat{
    padding: 0px 5% 5% 5%;
  }


  .div-dark{
    width: 550px;
        // height: auto;
        z-index: 100;
    // position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background: red;
  }
  .css-130{
    color: var(--color-primary);
  }

  .container-resp{
    display: flex;
    width: 100%;
    flex-flow:column;
    justify-content: space-between;
}