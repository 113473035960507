@font-face {
    font-family: 'fontello';
    src: url('../font/fontello.ttf');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"]:before, [class*=" icon-"]:before {
    font-family: "fontello";
}

.icon-key:before { content: '\e800'; } /* '' */
.icon-eye-off:before { content: '\e801'; } /* '' */
.icon-eye:before { content: '\e802'; } /* '' */
.icon-cancel:before { content: '\e803'; } /* '' */
.icon-ok-circled:before { content: '\e804'; } /* '' */
.icon-adjust:before { content: '\e805'; } /* '' */
.icon-down-open-big:before { content: '\e806'; } /* '' */
.icon-cancel-1:before { content: '\e807'; } /* '' */
.icon-attention-alt:before { content: '\e808'; } /* '' */
.icon-check:before { content: '\e809'; } /* '' */
.icon-lock-filled:before { content: '\e80b'; } /* '' */
.icon-right-open:before { content: '\f006'; } /* '' */
.icon-left-open:before { content: '\f007'; } /* '' */
.icon-filter:before { content: '\f0b0'; } /* '' */
.icon-language:before { content: '\f1ab'; } /* '' */