.inputFile--container{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin: 10px 0;
    box-sizing: border-box;
    label{
        width: 80%;
        font-size: var(--font-size-tertiary);
    }
    input{
        margin: 0;
        padding: 0 5px;
        width: 100%;
        height: 32px;
        box-sizing: border-box;
        transition: all 0.3s;
        color: var(--color-neutral-dark-II);
        border: 1px solid var(--color-neutral-light-I);
        border-radius: 3px;
        &::placeholder{
            color: var(--color-neutral-base)
        }
        &:hover{
            transition: all 0.3s;
            border: 1px solid var(--color-neutral-base);
            border-radius: 3px;
        }
        &:focus{
            outline: none;
            border: none;
            transition: all 0.3s;
            box-shadow: 0px 0px 10px 3px var(--color-neutral-light-I);
        }
        &::placeholder {
            color: var(--color-neutral-dark-I);
        }
    }
    .inputFielt--container-input{
        width: 100%;
        height: auto;
        position: relative;
        display: flex;
        span{
            top: 0;
            right: 0;
            width: 32px;
            height: 32px;
            display: flex;
            cursor: pointer;
            // position: absolute;
            align-items: center;
            transition: all 0.3s;
            justify-content: center;
            background:white;
            box-sizing: border-box;
            color: var(--color-neutral-base);
            border: 1px solid var(--color-neutral-light-I);
            font-size: 19px;            
            &:hover{
                transition: all 0.3s;
                background: var(--color-neutral-light-I);
            }
            i{color: var(--color-primary);}
        }
    }
    .est{
        display: flex;
        justify-content: space-between;
        width: 100%;
        .politics{
            font-size: var(--font-size-pentanary);
            color: var(--color-primary);
            font-weight: 600;
        }
        .content{
            float: left;
        }
    }
}

.polits{
    li{
        list-style: initial;
    }
    
}
.ms-TeachingBubble-body{
     margin-left: 15px;
     margin-bottom: 0px;   
}
.teching-bubble{
    .ms-Button{
        background: red;
    }
}
.ms-Callout-main, .ms-Callout-beak{
    background: var(--color-primary);
    .ms-Button-label{
        color: var(--color-primary);

    }
    .ms-Button{
        background: white;
    }
}


.input-file-fluent{
    input{
        border: none;
        
      &:hover{
          border: none
        }
    }
    .ms-TextField:active {
      outline: none;
    }
    // .ms-FocusZone:focus {
    //     outline: none;
    //   }
    .ms-TextField-fieldGroup{
    border: 1px solid var(--color-neutral-light-I);
      border-radius: 4px;
      height: auto;

      &:hover{
        border: 1px solid var(--color-neutral-base);
        &::after{
            border: none;
            // box-shadow: 0px 0px 10px 3px var(--color-neutral-light-I);
            
        }
      }
    &:focus{
        border: 1px solid var(--color-neutral-light-I);
    }
    &::after{
        border: none;
        // box-shadow: 0px 0px 10px 3px var(--color-neutral-light-I);
        
    }
    }
    .ms-TextField-wrapper:focus-visible{
        border: 1px solid var(--color-neutral-light-I);
        &:focus{
            border: 1px solid var(--color-neutral-light-I);
        }
    }
}