.container--buttonBlock{
    width:100%;
    margin: 0;
    padding: 12px 5px;
    display: flex;
    flex-wrap: wrap;
    cursor: pointer;
    flex-direction: row;
    align-items: flex-start;
    box-sizing: border-box;
    border-bottom: 1px solid var(--color-neutral-light-I);
    color: var(--color-neutral-dark-II);
    font-size: var(--font-size-secondary);
    transition: all 0.3s;
    &:hover{
        background: var(--color-neutral-light-I);
        transition: all 0.3s;
    }
    .container--buttonBlock-icon{
        width: 10%;
        img{
            width: 90%;
        }
    }
    .container--buttonBlock-text{
        width: 90%;
        p{
            margin: 0 5px;
            text-align: start;
        }
    }
}