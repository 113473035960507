.container--buttonFloat{
    width: 100%;
    height: 38px;
    margin: 10px 0;
    display: flex;
    flex-wrap: wrap;
    cursor: pointer;
    flex-direction: row;
    align-items: center;
    transition: all 0.3s;
    background: var(--color-neutral-light-II);
    box-shadow: 0px 4px 6px 1px var(--color-neutral-base);
    a{
        margin: 0;
        width: 24px;
        margin: 5px 10px; 
        text-align: center; 
        transition: all 0.3s;
        &:hover{
            transition: all 0.3s;
            border: none;
            background: var(--color-neutral-dark-I);
        } 
    }
    p{
        margin: 0;
        color: var(--color-primary);
        font-size: var(--font-size-secondary);
    }
    
}

.btnFloat-header{
    justify-content: center;
    >a{
        margin: 0 8px;
        padding: 0 5px;
        transition: all 0.3s;
        font-size: var(--font-size-secondary);
        &:hover{
            transition: all 0.3s;
            background: var(--color-neutral-base);
        }
    }
}

.btnFloat-footer{
    justify-content: flex-start;
    &:hover{
        box-shadow: none;
        transition: all 0.3s;
        background: var(--color-neutral-light-II);
    }
    a{
        &:hover{
            transition: all 0.3s;
            border: none;
            background: none;
        } 
    }
}