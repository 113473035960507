.container--captcha{
  float: right;
  position: unset;
  width: 98.5%;
  height: auto;
  background: var(--color-neutral-light-III);
  box-shadow: rgb(14 30 37 / 15%) 0px 2px 4px 0px, rgb(14 30 37 / 15%) 0px 0px 5px 0px;
  margin-bottom: 15px;
  margin-top: 15px;

  .ms-ChoiceField-wrapper label{
    background: transparent;
    height: 30px;
    
  }
  label{
    padding-top: 8px;
  }
  .ms-ChoiceField-innerField{
    padding-left: 18px;
    padding-right: 18px;
  }
  .ms-ChoiceField-field:checked{
    border: 1px solid rgb(0, 120, 212);
  }
}

  .label {
    text-align: center;
    display: block;
    position: relative;
    margin: 10px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    left: 9%;
  }

 .aa{
   width: 90%;
 }
 .ss{
  display: flex;
  width: auto;
  padding: 10px;
  left: 50%;
}

.iconLabel{
  display: flex;
}

.imgNameImage{
  margin: 0.5rem 1em 0 auto;
  width: 220px;
  height: 30px;
}

.ms-ChoiceFieldGroup-flexContainer{
  padding: 0 10px 0 10px;
}

.captchaStyle{
  .ms-ChoiceField-field::before{
    background-color: transparent;
    border-color:transparent;
  }
  .ms-ChoiceField-field::after{
    border-color: transparent;
    background: transparent;
  }
}

.pd-txt{
  padding: 10px;
}