.menu--user-cotainer{
    width: 100%;
    .ms-FocusZone{
        padding: 10px 0px;
        .ms-OverflowSet{
            flex-wrap: wrap;
            justify-content: space-between;
            .left-nav{
                margin: 0 -3px;
            }
            .right-nav{
                margin: 0 -12px 0 0;
            }
            button{
                width: 100%;
                color: var(--color-neutral-dark-III);
                background: var(--color-neutral-light-III);
                i{
                    font-weight: 600;
                    color: var(--color-primary);
                }
                
            }
        }
    }
}